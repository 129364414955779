"use client";

import { getEmailAndNameFromPrivyUser } from "@/utils/privy";
import * as amplitude from "@amplitude/analytics-browser";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";

const InitAnalytics = () => {
  const { user, ready, authenticated } = usePrivy();
  useEffect(() => {
    if (!ready || !authenticated || !user) return;
    const initAnalytics = async () => {
      const { email, name } = getEmailAndNameFromPrivyUser(user);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("Email", email ?? "");
      if (name) identifyEvent.set("Name", name);
      else {
        const name = email?.split("@")[0] ?? "";
        identifyEvent.set("Name", name);
      }

      amplitude.identify(identifyEvent);
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!, {
        // logLevel: amplitude.Types.LogLevel.Debug,
      });
    };
    initAnalytics();
  }, [user, ready, authenticated]);

  return null;
};

export default InitAnalytics;
