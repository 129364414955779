import React from "react";

interface IProps {
  logoImage: JSX.Element;
  infoImage: JSX.Element;
  infoText: string;
}
const ResponsiveView = ({ logoImage, infoImage, infoText }: IProps) => {
  return (
    <div
      className="z-[7777] w-[100vw] h-[100vh] relative bg-base-bg 
    dark:bg-dark-base-bg"
    >
      <span className="flex justify-center items-center pt-10">
        {logoImage}
      </span>

      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
      flex flex-col items-center gap-16"
      >
        <div className="flex items-center flex-col h-full">
          <span>{infoImage}</span>
          <span
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            font-bold text-xl mt-4 text-center w-64"
          >
            {infoText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveView;
